import { Fade } from "react-awesome-reveal";

export default function About() {
  return (
    <div
      name="about"
      className="w-full h-screen bg-gradient-to-b from-black to-gray-800 p-4 text-white"
    >
      <div className="flex flex-col p-4 justify-center max-w-screen-lg mx-auto h-full">
        <Fade cascade>
          <div className="pb-8">
            <p className="text-4xl font-bold inline border-b-4 border-gray-500">
              About
            </p>
          </div>
          <p className="text-xl mt-20">
            My journey in the world of technology began with a degree in
            Computer Science, where I honed my analytical and problem-solving
            skills through game development. My ability to collaborate with
            designers, understand user requirements, and implement elegant
            solutions has continuously enhancing my design skills. If you're
            looking for a passionate and a hardworking web developer, let's
            connect! feel free to reach out.
          </p>
          <br />
          <p className="text-xl">
            Being able to visually display my ideas into projects is something
            that has become my true passion. I thrive on turning ideas into
            interactive and visually appealing websites that provide seamless
            user experiences. Armed with proficiency in HTML, CSS, JavaScript,
            and the latest web technologies from my education such as ReactJS
            and Remix.run.
          </p>
        </Fade>
      </div>
    </div>
  );
}
