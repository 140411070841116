import githubLogo from "../assets/logos/github.svg";
import linkedInLogo from "../assets/logos/linkedin.svg";
import mailLogo from "../assets/logos/mail.svg";
import pdfFile from "../assets/logos/user-pen.svg";
export default function SocialLinks() {
  const links = [
    {
      id: 1,
      child: (
        <>
          LinkedIn <img src={linkedInLogo} alt="LinkedIn" />
        </>
      ),
      href: "https://www.linkedin.com/in/dennis-ly-37538a13a/",
      style: "rounded-tr-md",
    },
    {
      id: 2,
      child: (
        <>
          Github <img src={githubLogo} alt="Github" />
        </>
      ),
      href: "https://github.com/dragonwingzify",
    },
    {
      id: 3,
      child: (
        <>
          Mail <img src={mailLogo} alt="mail" />
        </>
      ),
      href: "mailto:dennisnly@gmail.com",
    },
    {
      id: 4,
      child: (
        <>
          Resume <img src={pdfFile} alt="Resume" />
        </>
      ),
      href: "/CV-kort.pdf",
      style: "rounded-br-md",
      download: true,
    },
  ];
  return (
    <div className="flex-col top-[35%] left-0 fixed lg:flex hidden ">
      <ul>
        {links.map(({ id, child, href, style, download }) => (
          <li
            key={id}
            className={
              "flex justify-between items-center w-40 h-14 px-4 ml-[-100px] hover:ml-[-10px] bg-gray-500 hover:rounded-md duration-300" +
              " " +
              style
            }
          >
            <a
              href={href}
              className="flex justify-between items-center w-full text-white"
              download={download}
              target="_blank"
              rel="noopener noreferrer"
            >
              <>{child}</>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
