import { Fade } from "react-awesome-reveal";

export default function Contact() {
  return (
    <div
      name="contact"
      className="w-full h-screen bg-gradient-to-b from-black to-gray-800 p-4 text-white"
    >
      <div className="flex flex-col p-4 justify-center max-w-screen-lg mx-auto h-full">
        <Fade cascade>
          <div className="pb-8">
            <p className="text-4xl font-bold inline border-b-4 border-gray-500">
              Contact
            </p>
            <p className="py-6">
              Submit the form below to get in touch with me
            </p>
          </div>
        </Fade>
        <Fade cascade>
          <div className="flex justify-start items-center">
            <form
              action="https://getform.io/f/4e045d42-2a35-46f9-82fe-3e609f7612a4"
              method="post"
              className="flex flex-col w-full md:w-3/4"
            >
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                className="p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
              />
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                className="my-4 p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
              />
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="10"
                placeholder="Message"
                className="p-2 bg-transparent border-2 rounded-md text-white focus:outline-none"
              ></textarea>
              <button
                type="submit"
                className="text-white bg-gradient-to-b from-cyan-500 to-blue-500 px-6 py-3 my-8 mx-auto flex items-center rounded-md hover:scale-110 duration-300"
              >
                Submit
              </button>
            </form>
          </div>
        </Fade>
      </div>
    </div>
  );
}
