import CandidatePortal from "../assets/projects/CandidatePortal.png";
import CustomerPortal from "../assets/projects/CustomerPortal.png";
import KeywordInsights from "../assets/projects/KeywordInsights.png";
import PriceComparison from "../assets/projects/PriceComparison.png";
import { Zoom } from "react-awesome-reveal";

export default function Projects() {
  const projects = [
    {
      id: 1,
      title: "Candidate Portal",
      description:
        "A portal for candidates to apply for jobs and other features.",
      image: CandidatePortal,
      demo: "https://golden-candidates.onrender.com/",
    },
    {
      id: 2,
      title: "Customer Portal",
      description:
        "Portal for viewing contracts and features. Unavailable for privacy reasons.",
      image: CustomerPortal,
    },
    {
      id: 3,
      title: "Keyword Insights",
      description:
        "Gives insight from a keyword search on Google based on ranking.",
      image: KeywordInsights,
      demo: "https://keyword-insights.conversio.dk/",
    },
    {
      id: 4,
      title: "Price Comparison",
      description:
        "Send an email to a specific email address and get a price comparison",
      image: PriceComparison,
      demo: "https://price-comparison.conversio.dk",
    },
  ];

  return (
    <div
      name="projects"
      className="w-full h-screen bg-gradient-to-b from-black to-gray-800 p-4 text-white "
    >
      <div className="flex flex-col p-4 justify-center max-w-screen-lg mx-auto h-full ">
        <Zoom cascade>
          <div className="pb-8">
            <p className="text-4xl font-bold border-b-4  inline-block border-gray-500">
              Projects
            </p>
            <p className="py-6">Check out some of my work right here</p>
          </div>
        </Zoom>
        <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-8 px-12 sm:px-0 ">
          <Zoom cascade>
            {projects.map(({ id, title, description, image, demo }) => (
              <div
                key={id}
                className="border-y-2 shadow-gray-600 rounded-lg duration-200 hover:scale-105 "
              >
                <p className="text-2xl text-white text-center">{title}</p>
                <img src={image} alt={description} />
                <div className="flex-wrap items-center justify-center">
                  <p className="w-full py-3 duration-200 hover:scale-105 text-start">
                    {description}
                  </p>
                  <a href={demo} target="_blank" rel="noreferrer">
                    <button className=" w-full  px-6 py-3 duration-200 hover:scale-105 rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 ">
                      View project
                    </button>
                  </a>
                </div>
              </div>
            ))}
          </Zoom>
        </div>
      </div>
    </div>
  );
}
