import avatar from "../assets/images/dennis-ly.png";
import chevronRight from "../assets/logos/chevron-right.svg";
import { Link } from "react-scroll";
import { Fade } from "react-awesome-reveal";
export default function Home() {
  return (
    <div
      name="home"
      className="h-screen w-full bg-gradient-to-b from-black via-black to-gray-800 z-auto"
    >
      <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-between h-full px-4 md:flex-row">
        <div className="flex flex-col justify-center h-full">
          <Fade cascade>
            <h2 className="text-4xl sm:text-7xl font-bold text-white">
              I'm a Full Stack Developer
            </h2>

            <p className="text-gray-200 py-4 max-w-md">
              Greetings! I'm Dennis, a passionate computer science graduate with
              a bachelor specialization in web development.
            </p>

            <div>
              <Link
                to="projects"
                smooth
                duration={500}
                className=" group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer"
              >
                Portfolio
                <span className="group-hover:rotate-90 duration-300 ml-1">
                  <img src={chevronRight} alt="portfolio" />
                </span>
              </Link>
            </div>
          </Fade>
        </div>
        <Fade delay={1300}>
          <div>
            <img
              src={avatar}
              alt="profile"
              className="rounded-2xl mx-auto w-2/3 md:w-full"
            />
          </div>
        </Fade>
      </div>
    </div>
  );
}
