import htmlImage from "../assets/images/html.png";
import cssImage from "../assets/images/css.png";
import reactImage from "../assets/images/react.png";
import githubImage from "../assets/images/github.png";
import tailwindImage from "../assets/images/tailwind.png";
import phpImage from "../assets/images/php.png";
import javascriptImage from "../assets/images/javascript.png";
import { Zoom } from "react-awesome-reveal";

export default function Experience() {
  const experience = [
    {
      id: 1,
      title: "HTML",
      image: htmlImage,
      style: "shadow-orange-500",
    },
    {
      id: 2,
      title: "CSS",
      image: cssImage,
      style: "shadow-blue-500",
    },
    {
      id: 3,
      title: "React",
      image: reactImage,
      style: "shadow-blue-600",
    },
    {
      id: 4,
      title: "Github",
      image: githubImage,
      style: "shadow-gray-400",
    },
    {
      id: 5,
      title: "Tailwind",
      image: tailwindImage,
      style: "shadow-sky-400",
    },
    {
      id: 6,
      title: "PHP",
      image: phpImage,
      style: "shadow-gray-700",
    },
    {
      id: 6,
      title: "Javascript",
      image: javascriptImage,
      style: "shadow-yellow-500",
    },
  ];

  return (
    <div
      name="experience"
      className="w-full h-screen bg-gradient-to-b from-black to-gray-800 p-4 text-white"
    >
      <div className=" max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
        <div>
          <p className="text-4xl font-bold border-b-4 border-gray-500 inline">
            Experience
          </p>
          <p className="py-6">
            These are the technologies that I've worked with
          </p>
        </div>
        <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0">
          <Zoom cascade>
            {experience.map(({ id, title, image, style }) => (
              <div
                key={id}
                className={`shadow-md hover:scale-105 duration-500 py-2 rounded-lg ${style}`}
              >
                <img src={image} alt={title} className="w-20 mx-auto" />
                <p className="mt-4">{title}</p>
              </div>
            ))}
          </Zoom>
        </div>
      </div>
    </div>
  );
}
